import { initializeApp, getApps } from "firebase/app"

import { getAnalytics } from "firebase/analytics"

if (!getApps().length) {
    try {
        // Initialize Firebase
        initializeApp({
            apiKey: "AIzaSyCqdQHoiYu3eM5Vz_DgNsAihr3xX2VJ0Cs",
            authDomain: "aiac-website.firebaseapp.com",
            projectId: "aiac-website",
            storageBucket: "aiac-website.appspot.com",
            messagingSenderId: "103847305441",
            appId: "1:103847305441:web:22f81cff4f38bb3f8d97a0",
            measurementId: "G-LJ9P1ZPF46"
        });
    } catch (error) {
        console.log("Firebase initialization error: ", error.stack);
    }
}

export default getAnalytics;