import '../styles/globals.css'
import 'tailwindcss/tailwind.css'
import { useEffect } from 'react'

import * as Sentry from "@sentry/browser";
import { Integrations as TracingIntegrations } from "@sentry/tracing";

import { AnimatePresence } from "framer-motion";
import { useRouter } from 'next/dist/client/router';

import analytics from '../util/firebase/analytics';
import { logEvent, setCurrentScreen } from "firebase/analytics";

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      // Initialize Sentry
      Sentry.init({
        dsn: "https://26f061c335b94d83b9f694ce0c595ee2@o924596.ingest.sentry.io/5872835",

        // This enables automatic instrumentation (highly recommended), but is not
        // necessary for purely manual usage
        integrations: [new TracingIntegrations.BrowserTracing()],

        // To set a uniform sample rate
        tracesSampleRate: 0.2
      });

      // Initialize Firebase Analytics
      const logEventHandler = (url) => {
        setCurrentScreen(analytics(), url);
        logEvent(analytics(), "screen_view");
      };
      router.events.on('routeChangeComplete', logEventHandler);
      logEventHandler(window.location.pathname); // Logs first page that the user visits

      console.log("Made with ☕️.");

      // Remvove the event listener for firebase Analytics after unmount
      return () => {
        router.events.off('routeChangeComplete', logEventHandler);
      };
    }
  }, [router.events]);

  return (
    <AnimatePresence exitBeforeEnter>
      <Component {...pageProps} />
    </AnimatePresence>
  );
}

export default MyApp
